import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = [];

    connect() {
        this.checkVisibility();
        window.addEventListener("scroll", this.checkVisibility.bind(this));
    }

    disconnect() {
        window.removeEventListener("scroll", this.checkVisibility.bind(this));
    }

    checkVisibility() {
        if (window.scrollY === 0) {
            this.element.classList.remove("hidden");
            document.getElementById("master_cta").classList.add("d-none");
        } else {
            this.element.classList.add("hidden");
            document.getElementById("master_cta").classList.remove("d-none");
        }
    }
}
