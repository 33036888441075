import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["checkbox"];

  static values = {
    target: String
  }

  validate(event) {
    const anyChecked = this.checkboxTargets.some((checkbox) => checkbox.checked);

    if (!anyChecked) {
      // Prevent form submission and show an error
      event.preventDefault();
      document.getElementById('checkbox_warning').classList.remove('d-none');
    }
  }


  connect() {
    console.log("Common Controller connected");

    this.onDOMContentLoaded();

    document.addEventListener("contextmenu", this.disableRightClick);

    const slider = document.getElementById('before-after-slider');
    const before = document.getElementById('before-image');
    const beforeImage = before.getElementsByTagName('img')[0];
    const resizer = document.getElementById('resizer');

    let active = false;

    //Sort overflow out for Overlay Image
    document.addEventListener("DOMContentLoaded", function() {
      let width = slider.offsetWidth;
      console.log(width);
      beforeImage.style.width = width + 'px';
    });

    //Adjust width of image on resize
    window.addEventListener('resize', function() {
      let width = slider.offsetWidth;
      console.log(width);
      beforeImage.style.width = width + 'px';
    })

    resizer.addEventListener('mousedown',function(){
      active = true;
      resizer.classList.add('resize');

    });

    document.body.addEventListener('mouseup',function(){
      active = false;
      resizer.classList.remove('resize');
    });

    document.body.addEventListener('mouseleave', function() {
      active = false;
      resizer.classList.remove('resize');
    });

    document.body.addEventListener('mousemove',function(e){
      if (!active) return;
      let x = e.pageX;
      x -= slider.getBoundingClientRect().left;
      slideIt(x);
      pauseEvent(e);
    });

    resizer.addEventListener('touchstart',function(){
      active = true;
      resizer.classList.add('resize');
    });

    document.body.addEventListener('touchend',function(){
      active = false;
      resizer.classList.remove('resize');
    });

    document.body.addEventListener('touchcancel',function(){
      active = false;
      resizer.classList.remove('resize');
    });

    //calculation for dragging on touch devices
    document.body.addEventListener('touchmove',function(e){
      if (!active) return;
      let x;

      let i;
      for (i=0; i < e.changedTouches.length; i++) {
        x = e.changedTouches[i].pageX;
      }

      x -= slider.getBoundingClientRect().left;
      slideIt(x);
      pauseEvent(e);
    });

    function slideIt(x){
      let transform = Math.max(0,(Math.min(x,slider.offsetWidth)));
      before.style.width = transform+"px";
      resizer.style.left = transform-0+"px";
    }

    //stop divs being selected.
    function pauseEvent(e){
      if(e.stopPropagation) e.stopPropagation();
      if(e.preventDefault) e.preventDefault();
      e.cancelBubble=true;
      e.returnValue=false;
      return false;
    }

    document
        .querySelector(".mobile_menu_handler")
        .addEventListener("click", function () {
          const offcanvasElement = document.getElementById("offcanvasTop");
          const bsOffcanvas = new bootstrap.Offcanvas(offcanvasElement);
          bsOffcanvas.show();
        });

    const beerSliderElement = document.getElementById("beer-slider")
    if (beerSliderElement) {
      const slider = new BeerSlider(beerSliderElement, { start: 50 });
    }

    const searchButton = document.getElementById("searchButton");
    const mobileMenuButton = document.querySelector(".mobile_menu_handler");
    const offcanvasElement = document.getElementById("offcanvasTop");

    const closeIconSvg = `
<svg id="closeIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-bs-dismiss="offcanvas">
  <path
    fill-rule="evenodd" clip-rule="evenodd"
    d="M6.293 7.293a1 1 0 011.414 0L12 10.586l4.293-4.293a1 1 0 111.414 1.414L13.414 12l4.293 4.293a1 1 0 01-1.414 1.414L12 13.414l-4.293 4.293a1 1 0 01-1.414-1.414L10.586 12 6.293 7.707a1 1 0 010-1.414z"
    fill="#D9D9D9"
  />
</svg>
`;

    const menuIconSvg = `
<svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M2.3335 2H26.3335M2.3335 11H26.3335M2.3335 20H26.3335" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
</svg>
`;

    // Function to switch the close icon
    function switchToCloseIcon(button) {
      button.innerHTML = closeIconSvg;
      button.blur();
      document.body.focus();
    }

    // Function to switch back to the original icons
    function switchToOriginalIcons(button, originalIconSvg) {
      button.innerHTML = originalIconSvg;
      button.blur();
      document.body.focus();
    }

    // Offcanvas button click icon change
    mobileMenuButton.addEventListener("click", function () {
      switchToCloseIcon(mobileMenuButton);
      mobileMenuButton.blur();
      document.body.focus();
    });

    // Listens to the offcanvas close event
    offcanvasElement.addEventListener("hidden.bs.offcanvas", function () {
      switchToOriginalIcons(mobileMenuButton, menuIconSvg);
      mobileMenuButton.blur();
      document.body.focus();
    });

    // Switch back to the original icons when the close icon is clicked
    document.addEventListener("click", function (event) {
      if (event.target.closest("#closeIcon")) {
        console.log("Line 94 - 1");
        // if (searchButton.innerHTML.includes("closeIcon")) {
        //   switchToOriginalIcons(searchButton, searchIconSvg);
        // }
        if (mobileMenuButton.innerHTML.includes("closeIcon")) {
          switchToOriginalIcons(mobileMenuButton, menuIconSvg);
          mobileMenuButton.blur();
          document.body.focus();
        }
      }
    });

  }

  disconnect() {
    document.removeEventListener("contextmenu", this.disableRightClick);
  }

  onDOMContentLoaded() {
    console.log("DOM fully loaded and parsed");

    // Initialize the projects map if the map container is present
    const mapContainer = document.getElementById("projectsMap");
    if (mapContainer) {
      setMap();
    }

    const modal = document.getElementById("consultationModal");
    const link = document.getElementById("consultationLink");
    const close = document.getElementById("modalClose");

    // Keep modal hidden when page loads
    if (modal) {
      modal.style.display = "none";
    }

    // Open the modal when the link is clicked
    if (link) {
      link.addEventListener("click", function (event) {
        event.preventDefault(); // Prevent page reload
        modal.style.display = "flex"; // Show modal
      });
    }

    // Close the modal when the close button is clicked
    if (close) {
      close.addEventListener("click", function () {
        modal.style.display = "none"; // Hide modal
      });
    }

    // Close the modal when the user clicks outside of it
    window.addEventListener("click", function (event) {
      if (event.target === modal) {
        modal.style.display = "none"; // Hide modal
      }
    });

    const searchContainer = document.getElementById("searchContainer");
    const searchHandler = document.getElementById("searchButton");

    // Hide the search container when the page loads
    // searchContainer.style.display = "none";

    // Search button click event handler
    // searchHandler.addEventListener("click", function () {
    //   //if (window.innerWidth <= 768) {
    //     toggleSearch(); // Toggle search box on mobile
    //   //}
    // });

    // Check clickability when screen size changes
    function updateButtonState() {
      if (window.innerWidth <= 768) {
        searchHandler.style.pointerEvents = "auto"; // Make clickable on mobile
      } else {
        searchHandler.style.pointerEvents = "none"; // Make unclickable on desktop
      }
    }

    //updateButtonState();
    //window.addEventListener("resize", updateButtonState);
  }

  showPassword() {
    let current_password_input = this.currentPasswordInputTarget;
    if (current_password_input.type === "password") {
      current_password_input.type = "text";
    } else {
      current_password_input.type = "password";
    }
  }

  submitTargetForm(event) {
    const targetFormId = this.targetValue; // Get the value from the static value
    const targetForm = document.getElementById(targetFormId);

    if (targetForm) {
      console.log("##### Target Form ID: " + targetFormId);
      targetForm.submit();
    } else {
      console.error("Form not found with ID: " + targetFormId);
    }
  }

  formatPhoneNumber(event) {
    const input = event.target;
    const phoneNumber = input.value.replace(/\D/g, "");
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength > 10) {
      input.value = phoneNumber.slice(0, 10);
    }

    if (phoneNumberLength > 6) {
      input.value = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    } else if (phoneNumberLength > 3) {
      input.value = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    } else {
      input.value = phoneNumber;
    }
  }

  disableRightClick(event) {
    event.preventDefault();
    console.log("Right-click is disabled on this page.");
  }

}
