import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["tokenInput"]

    connect() {
        console.log("Connected reCAPTCHA controller...");
        this.siteKey = this.element.dataset.recaptchaSiteKey;
        this.formType = this.element.dataset.formType;

        console.log("reCAPTCHA site key:", this.siteKey);
        console.log("reCAPTCHA form type:", this.formType);

        this.refreshToken();

        this.loadRecaptchaScript().then(() => {
            this.refreshToken();
            this.refreshInterval = setInterval(() => this.refreshToken(), 110000); // Refresh every 2 minutes
        });
    }

    disconnect() {
        clearInterval(this.refreshInterval);
    }

    async loadRecaptchaScript() {
        return new Promise((resolve, reject) => {
            if (typeof grecaptcha !== "undefined") {
                console.log("grecaptcha already loaded...");
                resolve();
                return;
            }

            console.log("Loading reCAPTCHA script...");
            const script = document.createElement("script");
            script.src = `https://www.google.com/recaptcha/api.js?render=${this.siteKey}`;
            script.async = true;
            script.defer = true;
            script.onload = () => {
                console.log("reCAPTCHA script loaded.");
                resolve();
            };
            script.onerror = () => {
                console.error("Failed to load reCAPTCHA script.");
                reject();
            };

            document.head.appendChild(script);
        });
    }

    async ensureRecaptchaLoaded() {
        console.log("Ensuring reCAPTCHA is loaded...");
        // Wait for the `grecaptcha` object to be available
        return new Promise((resolve) => {
            const checkRecaptcha = () => {
                console.log("checkRecaptcha...");
                if (typeof grecaptcha !== "undefined") {
                    console.log("grecaptcha !== undefined...");
                    resolve();
                } else {
                    console.log("settimeout here 1...");
                    setTimeout(checkRecaptcha, 100); // Retry every 100ms
                }
            };
            checkRecaptcha();
        });
    }

    refreshToken() {
        console.log("Refreshing reCAPTCHA token...");
        grecaptcha.ready(() => {
            console.log("grecaptcha.ready 2...");
            grecaptcha
                .execute(this.siteKey, { action: this.formType })
                .then((token) => {
                    console.log("reCAPTCHA token refreshed:", token);
                    this.tokenInputTarget.value = token;
                })
                .catch((error) => {
                    console.error("Failed to refresh reCAPTCHA token:", error);
                });
        });
    }
}
