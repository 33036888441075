import "@hotwired/turbo-rails"
import "./controllers/web"
import * as bootstrap from "bootstrap"

console.log('Hello World from Deck Remodelers!');

function toggleElement(element) {
    document.getElementById(element).classList.toggle('d-none');
}

window.toggleElement = toggleElement;

// Toggle search box ########## //
function toggleSearch() {
    const searchContainer = document.getElementById("searchContainer");
    const searchOpenIcon = document.getElementById("searchIcon");
    const searchHideIcon = document.getElementById("closeIcon");
    if (
        searchContainer.style.display === "none" ||
        searchContainer.style.display === ""
    ) {
        console.log("CLOSED Opening search...");
        searchContainer.style.display = "flex"; // Show search box
        searchOpenIcon.style.display = "none"; // Hide Search icon
        searchHideIcon.style.display = "block"; // Show close icon
    } else {
        console.log("OPEN Closing search...");
        searchContainer.style.display = "none"; // Hide search box
        searchOpenIcon.style.display = "block"; // Show Search icon
        searchHideIcon.style.display = "none"; // Hide close icon
    }
}
window.toggleSearch = toggleSearch;

// Initiate search and hide search box ########## //
function performSearch() {
    console.log("Searching...");
    const searchContainer = document.getElementById("searchContainer");
    searchContainer.style.display = "none";
}
window.performSearch = performSearch;

function expandAll() {
    const elements = document.querySelectorAll(".collapse");
    elements.forEach(function (element) {
        const bsCollapse = new bootstrap.Collapse(element, {
            toggle: false,
        });
        bsCollapse.show();
    });
}
window.expandAll = expandAll;

function collapseAll() {
    const elements = document.querySelectorAll(".collapse");
    elements.forEach(function (element) {
        const bsCollapse = new bootstrap.Collapse(element, {
            toggle: false,
        });
        bsCollapse.hide();
    });
}
window.collapseAll = collapseAll;

// document.querySelector(".mobile_menu_handler").addEventListener("click", function () {
//     const offcanvasElement = document.getElementById("offcanvasTop");
//     const bsOffcanvas = new bootstrap.Offcanvas(offcanvasElement);
//     bsOffcanvas.show();
// });

// VIDEO ########## //
// Find the video element
const homepageVideoContent = document.getElementById("homepageVideoPlayer");
const videoContent = document.getElementById("videoContent");
const muteButton = document.getElementById("muteButton");
const playPauseButton = document.getElementById("playPauseButton");

// Event listener for the mute button
if (muteButton){
    muteButton.addEventListener("click", function () {
        if (videoContent) {
            if (videoContent.muted) {
                videoContent.muted = false;
                muteButton.querySelector("img").src = volumeUnMuteImageUrl; // Icon for sound off
            } else {
                videoContent.muted = true;
                muteButton.querySelector("img").src = volumeMuteImageUrl; // Icon for sound on
            }
        }
        if (homepageVideoContent) {
            if (homepageVideoContent.muted) {
                homepageVideoContent.muted = false;
                muteButton.src = volumeUnMuteImageUrl; // Icon for sound off
            } else {
                homepageVideoContent.muted = true;
                muteButton.src = volumeMuteImageUrl; // Icon for sound on
            }
        }
    });
}

// Event listener for the play/pause button
if (playPauseButton) {
    playPauseButton.addEventListener("click", function () {
        if (videoContent) {
            if (videoContent.paused) {
                videoContent.play();
                playPauseButton.querySelector("img").src = videoPauseImageUrl; // Pause icon
            } else {
                videoContent.pause();
                playPauseButton.querySelector("img").src = videoPlayImageUrl; // Play icon
            }
        }
        if (homepageVideoContent) {
            if (homepageVideoContent.paused) {
                homepageVideoContent.play();
                playPauseButton.src = videoPauseImageUrl; // Pause icon
            } else {
                homepageVideoContent.pause();
                playPauseButton.src = videoPlayImageUrl; // Play icon
            }
        }
    });
}
// VIDEO ########## //

window.addEventListener("scroll", function () {
    const scrollpos = window.scrollY;
    const add_class_on_scroll = () => document.getElementById("page_header").classList.add("stick");
    const remove_class_on_scroll = () => document.getElementById("page_header").classList.remove("stick");

    if (scrollpos >= 10) {
        add_class_on_scroll();
    } else {
        remove_class_on_scroll();
    }
});

// window.addEventListener("turbo:load", () => {
//     console.log("turbo:load event fired...");
//     const recaptchaForms = document.querySelectorAll("[data-controller='recaptcha']");
//     console.log("recaptchaForms:", recaptchaForms);
//     recaptchaForms.forEach((form) => {
//         const recaptchaController = Stimulus.getControllerForElementAndIdentifier(
//             form,
//             "recaptcha"
//         );
//         if (recaptchaController) {
//             recaptchaController.loadRecaptchaScript();
//         }
//     });
// });